const SET_LANGUAGE = "SET_LANGUAGE";
const FETCH_BEGIN = "FETCH_BEGIN";
const FETCH_SUCCESS = "FETCH_SUCCESS";
const FETCH_ERROR = "FETCH_ERROR";
const OPEN_SIDEBAR = "OPEN_SIDEBAR";
const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
const COUNT_COMPONENTS = "COUNT_COMPONENTS";
const SET_CURRENT_INDEX = "SET_CURRENT_INDEX";

export {
  SET_LANGUAGE,
  FETCH_BEGIN,
  FETCH_SUCCESS,
  FETCH_ERROR,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  COUNT_COMPONENTS,
  SET_CURRENT_INDEX,
};
